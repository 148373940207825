<template>
  <article class="subpage-banner">
    <div
      class="subpage-banner__wrapper"
      :class="colorClass"
    >
      <div class="subpage-banner__content">
        <h1
          v-if="subheader"
          class="subpage-banner__subtitle"
          v-html="subheader"
        />

        <T3HtmlParser
          v-if="bodytext"
          class="subpage-banner__text"
          :content="bodytext"
        />
      </div>

      <UiImg
        v-if="logo"
        :image="logo"
        class="svg-icon svg-icon--invert subpage-banner__icon"
      />
    </div>

    <UiImg
      v-if="image"
      :image="image"
      class="subpage-banner__image"
    />
  </article>
</template>

<script>
import { UiImg } from '~ui/components'

export default {
  components: { UiImg },
  props: {
    header: {
      type: String,
      default: ''
    },
    subheader: {
      type: String,
      default: ''
    },
    bodytext: {
      type: String,
      default: ''
    },
    image: {
      type: Array,
      default: () => []
    },
    logo: {
      type: Array,
      default: () => []
    },
    themeColor: {
      type: String,
      default: 'macopedia-blue'
    }
  },
  computed: {
    colorClass() {
      return this.themeColor
        ? `color-${this.themeColor}`
        : 'color-macopedia-blue'
    }
  }
}
</script>

<style lang="scss">
$article-w: 470px;

.subpage-banner {
  $base: &;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  overflow: hidden;

  &__wrapper {
    width: 100%;
    padding: spacing(xxl) spacing(md);
    overflow: hidden;
    position: relative;
    isolation: isolate;
  }

  &__content {
    text-align: center;
    margin: 0 auto rem(26px);
    max-width: $article-w;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }

  &__subtitle {
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: spacing(md);

    @include media-query(md) {
      margin-bottom: 0;
      transform: translateX(-50%);
    }

    span {
      font-weight: 500;
    }
  }

  &__text {
    font-weight: 700;
    margin: 0;
    font-weight: normal;

    @include media-query(md) {
      text-align: left;
      margin-bottom: 0;
      transform: translateX(50%);
    }
  }

  &__image {
    margin-top: rem(-65px);
    display: block;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    z-index: z-index(over);

    @include media-query(sm) {
      max-width: rem(1000px);
    }
  }

  &__icon.svg-icon {
    position: absolute;
    top: 50%;
    left: 10%;
    width: rem(400px);
    height: rem(400px);
    z-index: z-index(below);
    opacity: 0.125;
    transform: translateY(-50%);

    &[src*='neuca'],
    &[src*='trefl'] {
      filter: invert(0);
    }
  }

  strong {
    color: color(primary);
  }

  a:not(:hover) {
    color: #f7981c;
  }
}
</style>
